import type { ServerSummaryAggregate } from './DataObject.SummaryData.ts';


export function serverAggregatePolyfill(pAggregates: ServerSummaryAggregate[], data: any) {
        const result: Record<string, any> = {};
        if (data) {
            pAggregates.forEach(aggregate => {
                switch (aggregate.aggregate) {
                    case 'SUM':
                        result[aggregate.name] = data.reduce((sum, item) => {
                            if (sum == null) {
                                sum = item[aggregate.name];
                            } else {
                                sum += item[aggregate.name];
                            }
                            return sum;
                        }, undefined as any);
                        break;
                    case 'AVG':
                        const sum = data.reduce((sum, item) => {
                            if (sum == null) {
                                sum = item[aggregate.name];
                            } else {
                                sum += item[aggregate.name];
                            }
                            return sum;
                        }, undefined as any);
                        result[aggregate.name] = sum / data.length;
                        break;
                    case 'MAX':
                        result[aggregate.name] = data.reduce((max, item) => {
                            if ((max == null || max < item[aggregate.name])) {
                                max = item[aggregate.name];
                            }
                            return max;
                        }, undefined as number|undefined);
                        break;
                    case 'MIN':
                        result[aggregate.name] = data.reduce((min, item) => {
                            if ((min == null || min > item[aggregate.name])) {
                                min = item[aggregate.name];
                            }
                            return min;
                        }, undefined as number|undefined);
                        break;
                    case 'COUNT':
                        result[aggregate.name] = data.reduce((count, item) => {
                            if (item[aggregate.name] != null) { count++; }
                            return count;
                        }, 0);
                        break;
                }
            });
        }

        return Promise.resolve(result);
    }